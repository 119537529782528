<template>
  <div>
    <preferences-modal
      @preferences-changed="preferencesChanged"
    ></preferences-modal>
    <div v-if="!productId || !product">
      <div class="row">
        <div class="col">
          <base-header></base-header>
          <p>
            Unfortunately your search strategy did not match any records in the
            database.
          </p>
          <p>
            Please <a :href="$baseUrl + 'menus/biblio'">click here</a> to return
            to the Search Form so that you can try again.
          </p>
        </div>
      </div>
    </div>
    <div v-else-if="product && $permitted('biblio/view')">
      <booksonix-tour
        v-if="product.getProductStatus.getProductStatus !== 'deleted'"
        :name="tour.name"
        :steps="tourSteps"
        :options="{ highlight: true }"
      ></booksonix-tour>
      <div class="row">
        <div class="col">
          <base-header v-if="searchForm == 'biblio'">
            Full (View / Edit Title Records)
          </base-header>
          <base-header v-else-if="searchForm == 'biblio-archive'">
            Full (Archived Title Records)
          </base-header>
          <base-header v-else-if="searchForm == 'biblio-template'">
            Full (Default Data Templates)
          </base-header>
          <base-header v-else-if="searchForm == 'workflow'">
            Full (Workflow / Production Scheduling)
          </base-header>
          <base-header v-else-if="searchForm == 'marketingmaterials'">
            Full (Printed &amp; Electronic Marketing Materials)
          </base-header>
          <base-header v-else-if="searchForm == 'export-industrystandard'">
            Full (Industry Standard Export)
          </base-header>
          <base-header v-else-if="searchForm == 'export-userdefined'">
            Full (User Defined Export)
          </base-header>
          <base-header v-else-if="searchForm == 'export-onix'">
            Full (Industry Standard XML Export)
          </base-header>
          <base-header v-else-if="searchForm == 'dissemination'">
            Full (Dissemination)
          </base-header>
          <base-header v-else-if="searchForm == 'foreignrights'">
            Full (Foreign Rights)
          </base-header>
          <base-header v-else>Full (View / Edit Title Records)</base-header>
        </div>
      </div>
      <product-summary-form-header
        v-if="product.getProductStatus"
        :product-id="productId"
        :unique-id="product.getPublisherUniqueID"
        :pub-date="
          product.getPublishingDates
            ? product.getPublishingDates.getPublicationDate
            : null
        "
        :title="
          product.getProductStatus.getTemplateName
            ? product.getProductStatus.getTemplateName
            : product.getMainTitle
        "
        :format="
          product.getFormatName
            ? product.getFormatName +
              (product.getFormatDetailsNames &&
              product.getFormatDetailsNames.length > 0 &&
              isEbook
                ? ' – ' + product.getFormatDetailsNames[0]
                : '')
            : null
        "
        :date-options="dateOptions"
      ></product-summary-form-header>
      <div
        v-if="searchId !== null && page !== null && pages !== null"
        class="row"
      >
        <div class="col">
          <div>
            <biblio-navigationbar
              class="mt-2"
              action="detailed/0"
              :search-id="searchId"
              :page="page"
              :pages="pages"
              :format="display"
              page-label="Record"
            ></biblio-navigationbar>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mt-2">
            <biblio-menubar
              location="biblio-detail"
              :search-id="searchId"
              :search-form="searchForm"
              :has-search-form="hasSearchForm"
              :marked-records="markedRecords"
              :marked-records-actions-enabled="true"
              :product-id="productId"
              :use-page-refresh="false"
              :page="page"
              :results-list-page="resultsListPage"
              :format="format"
              :custom-form="customForm"
              :display="display"
              :product-status="product.getProductStatus.getProductStatus"
              :product-title="product.getMainTitle"
              :can-synchronise="product.getTextualWorkProductIds !== null"
              :ftp-catalogue-details="ftpCatalogueDetails"
              :workflow-type="workflowType"
              data-v-step="biblio-detail-menubar"
              @statusChanged="onStatusChanged"
              @tagsApplied="onTagsApplied"
              @tagCreated="onTagCreated"
              @formatChanged="onFormatChanged"
              @sortBtnClick.prevent="sortBtnClick"
              @markclick.prevent="markClick"
            ></biblio-menubar>
          </div>
        </div>
      </div>
      <div
        v-if="product.getProductStatus.getProductStatus == 'deleted'"
        class="row"
      >
        <div class="col">
          <b-card
            header="Information - Record Deleted"
            border-variant="info"
            header-border-variant="info"
          >
            <div class="p-1">This record has been deleted</div>
          </b-card>
        </div>
      </div>
      <div v-else class="row">
        <div class="col">
          <b-card no-body class="mt-2">
            <b-tabs card @activate-tab="tabChanged">
              <b-tab id="detailtab" :active="defaultTab == 'details'">
                <template #title> Details </template>
                <biblio-detail-tab
                  ref="biblioDetailTab"
                  :product-id="productId"
                  :search-id="searchId"
                  :search-form="searchForm"
                  :logon-id="logonId"
                  :user-id-number="userIdNumber"
                  :product="product"
                  :media-types="mediaTypes"
                  :media-formats="mediaFormats"
                  :tags="tags"
                  :accreditation-stats="accreditationStats"
                  :dissemination-log="disseminationLog"
                  :audit-log="auditLog"
                  :page="page"
                  :pages="pages"
                  :return-to="returnTo"
                  :dissemination-error-status="disseminationErrorStatus"
                  :marked-records="markedRecords"
                  :display="display"
                  :has-custom-fields="hasCustomFields"
                  :share-media-modal-id="shareMediaModalId"
                  :date-options="dateOptions"
                  :custom-fields="customFields"
                  :is-ebook="isEbook"
                  :custom-form="customForm"
                  :workflow-type="workflowType"
                ></biblio-detail-tab>
              </b-tab>
              <b-tab
                v-if="
                  product.getProductStatus.getProductStatus !== 'D' &&
                  $permitted([
                    'export/dissemination',
                    'export/dissemination-schedule',
                    'biblio/audit-view+dissemination',
                  ])
                "
                title="Disseminations"
              >
                <disseminations-table
                  :product-id="productId"
                  :disseminations="disseminationLog"
                  :date-options="dateOptions"
                ></disseminations-table>
              </b-tab>
              <b-tab
                v-if="
                  product.getProductStatus.getProductStatus !== 'D' &&
                  $permitted([
                    'export/dissemination',
                    'export/dissemination-schedule',
                    'biblio/edit+dissemination',
                  ])
                "
                title="Statistics"
              >
                <biblio-detail-statistics
                  ref="biblioDetailStatistics"
                  :product-id="productId"
                  :accreditation-stats="accreditationStats"
                  :logon-id="logonId"
                  :pub-date="
                    product.getPublishingDates
                      ? product.getPublishingDates.getPublicationDate
                      : null
                  "
                  :date-options="dateOptions"
                  :load-on-mount="false"
                ></biblio-detail-statistics>
              </b-tab>
              <b-tab
                v-if="$permitted('biblio/audit-view')"
                title="Auditing Information"
                @click="$refs.productAuditTable.$refs.auditTable.reloadData()"
              >
                <product-audit-table
                  ref="productAuditTable"
                  :product-id="productId"
                  :audit-log="auditLog"
                  :date-options="dateOptions"
                  :audit-first-year="auditFirstYear"
                ></product-audit-table>
              </b-tab>
              <b-tab
                v-if="
                  workflowType == 'L' &&
                  $permitted('workflow/view') &&
                  product.getProductStatus.getProductStatus !== 'D'
                "
                :active="defaultTab == 'workflow'"
                title="Workflow"
                jest-workflow-tab
              >
                <product-workflow
                  :product-id="productId"
                  :workflow-object="product.getWorkflow"
                  :workflow-tasks="product.getWorkflowTasks"
                  :return-to="returnTo"
                  :marked-records="markedRecords"
                  :workflow-notes-enabled="workflowNotesEnabled"
                  :users-label="workflowUsersLabel"
                  :notes-label="workflowNotesLabel"
                  @workflowDeleted="workflowDeleted"
                ></product-workflow>
              </b-tab>
              <b-tab
                v-if="
                  workflowType == 'C' &&
                  $permitted('workflow/view') &&
                  product.getProductStatus.getProductStatus !== 'D'
                "
                :active="defaultTab == 'cakeworkflow'"
                title="Workflow"
                jest-cake-workflow-tab
              >
                <product-cake-workflow
                  :product-id="productId"
                  :workflow-object="cakeWorkflowJobs"
                  :workflow-templates="cakeWorkflowTemplates"
                  :return-to="returnTo"
                  :marked-records="markedRecords"
                ></product-cake-workflow>
              </b-tab>
              <b-tab
                v-if="
                  $permitted('foreignrights/view') &&
                  product.getProductStatus.getProductStatus !== 'D'
                "
                :active="defaultTab == 'foreignrights'"
                title="Foreign Rights"
              >
                <foreign-rights
                  :product-id="productId"
                  :foreign-rights="
                    product.getForeignRights
                      ? product.getForeignRights.get
                      : null
                  "
                  :search-id="searchId"
                  :page="page"
                  :return-to="returnTo"
                  :date-options="dateOptions"
                  :marked-records="markedRecords"
                  @foreignrightsDeleted="foreignrightsDeleted"
                ></foreign-rights>
              </b-tab>
              <b-tab v-if="$userHasCategory('H')" title="Onix 2.1 View">
                <template #title>
                  <span class="text-success">Onix 2.1 View</span>
                </template>
                <onix-view
                  ref="onix2"
                  :product-id="productId"
                  :logon-id="logonId"
                  :load-on-mount="false"
                >
                </onix-view>
              </b-tab>
              <b-tab v-if="$userHasCategory('H')" title="Onix 3.0 View">
                <template #title>
                  <span class="text-success">Onix 3.0 View</span>
                </template>
                <onix-view
                  ref="onix3"
                  :product-id="productId"
                  :logon-id="logonId"
                  version="3.0"
                  :load-on-mount="false"
                >
                </onix-view>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
      <div
        v-if="searchId !== null && page !== null && pages !== null"
        class="row mb-2"
      >
        <div class="col">
          <div>
            <biblio-navigationbar
              class="mt-2"
              action="detailed/0"
              :search-id="searchId"
              :page="page"
              :pages="pages"
              page-label="Record"
            ></biblio-navigationbar>
          </div>
        </div>
      </div>
      <sort-results-modal
        v-if="searchId"
        ref="sortModal"
        :search-id="searchId"
        :record="page"
      >
      </sort-results-modal>
      <share-media-modal
        :id="shareMediaModalId"
        :ref="shareMediaModalId"
      ></share-media-modal>
    </div>
  </div>
</template>

<script>
/**
 * Contains the presentation and application logic for the Biblio Detailed
 * Display page. Record information is passed in via props.
 */

import ProductSummaryFormHeader from "../../biblio/ProductSummaryFormHeader.vue";
import BiblioDetailTab from "../../biblio/BiblioDetailTab";
import BiblioDetailStatistics from "../../biblio/BiblioDetailStatistics.vue";
import OnixView from "../../biblio/OnixView.vue";
import DisseminationsTable from "../../biblio/DisseminationsTable.vue";
import ProductAuditTable from "../../biblio/ProductAuditTable.vue";
import ProductWorkflow from "../../biblio/ProductWorkflow.vue";
import ProductCakeWorkflow from "../../biblio/ProductCakeWorkflow.vue";
import MenuBar from "../../biblio/MenuBar.vue";
import NavigationBar from "../../biblio/NavigationBar.vue";
import SortResultsModal from "../../searchform/SortResultsModal.vue";
import { MarkedRecords } from "../../../MarkedRecords.js";
import _cloneDeep from "lodash/cloneDeep";
import { HTTP } from "../../../http-common.js";
import ShareMediaModal from "../../biblio/ShareMediaModal.vue";
import ForeignRights from "../../biblio/ForeignRights.vue";

export default {
  name: "BiblioDetailPage",

  components: {
    "biblio-detail-tab": BiblioDetailTab,
    "product-summary-form-header": ProductSummaryFormHeader,
    "biblio-detail-statistics": BiblioDetailStatistics,
    "onix-view": OnixView,
    "disseminations-table": DisseminationsTable,
    "product-audit-table": ProductAuditTable,
    "biblio-menubar": MenuBar,
    "biblio-navigationbar": NavigationBar,
    "sort-results-modal": SortResultsModal,
    "product-workflow": ProductWorkflow,
    "product-cake-workflow": ProductCakeWorkflow,
    "share-media-modal": ShareMediaModal,
    "foreign-rights": ForeignRights,
  },

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Name of the search form where the search
     * was performed
     */
    searchForm: {
      type: String,
      default: null,
    },

    /**
     *  Search type ('form', 'form-tag', 'quick', 'link')
     */
    searchFormType: {
      type: String,
      default: "form",
    },

    /**
     * The current user logon ID
     *
     * This is required for requests to the media manager API
     */
    logonId: {
      type: String,
      required: true,
    },

    /**
     * The logged in users ID Number
     *
     * This is required for requests to the media manager API
     */
    userIdNumber: {
      type: Number,
      required: true,
    },

    /**
     * Product data
     *
     * The data passed in must be an assosiative array (object in JS) of product data.
     * The keys of the array must match those defined in BooksonixProduct.php
     * Use the dump method to get an appropriate aray of product data
     */
    initialProduct: {
      type: Object,
      default: () => {},
    },

    /**
     * The list of media types available for product media
     *
     * Use BooksonixProductHelper getResourceTypes21
     */
    mediaTypes: {
      type: Array,
      default: () => [],
    },

    /**
     * The list of media formats available for product media
     *
     * Use BooksonixProductHelper getResourceFormats21
     */
    mediaFormats: {
      type: Array,
      default: () => [],
    },

    /**
     * List of tags that can be assigned to a product
     */
    tags: {
      type: Array,
      default: null,
    },

    /**
     * List of recipients with time that the product was
     * first sent to the recipient
     */
    accreditationStats: {
      type: Array,
      default: null,
    },

    /**
     * List of disseminations for the product
     */
    disseminationLog: {
      type: Array,
      default: null,
    },

    /**
     * Audit log
     */
    auditLog: {
      type: Array,
      default: null,
    },

    /**
     * First year that audit information is available for
     */
    auditFirstYear: {
      type: Number,
      default: 0,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      default: null,
    },

    /**
     * Number of pages
     */
    pages: {
      type: Number,
      default: null,
    },

    /**
     * Results list page number
     */
    resultsListPage: {
      type: Number,
      default: null,
    },

    /**
     * What type of workflow is in use. C for Cake and L for legacy.
     */
    workflowType: {
      type: String,
      default: "",
    },

    /**
     * New cake-based workflow data
     */
    cakeWorkflowJobs: {
      type: Array,
      default: () => [],
    },

    /**
     * List of cake-based available workflow templates
     */
    cakeWorkflowTemplates: {
      type: Array,
      default: () => [],
    },

    /**
     * Sets whether Workfow notes are enabled or not
     */
    workflowNotesEnabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Custom workflow label for users
     */
    workflowUsersLabel: {
      type: String,
      default: null,
    },

    /**
     * Custom workflow label for notes
     */
    workflowNotesLabel: {
      type: String,
      default: null,
    },

    /**
     * Detailed display format
     */
    format: {
      type: String,
      default: "full",
    },

    /**
     * Custom form exists and its title
     */
    customForm: {
      type: String,
      default: null,
    },

    /**
     * List of products custom fields that display
     * in the notes section
     */
    customFields: {
      type: Array,
      default: null,
    },

    /**
     * Object containing details of the FTP catalogue. If this is present
     * then the search form and results are being used to update the search
     * configuration on an existing catalogue.
     *
     * @property {string} mode The mode of the catalogue
     * @property {int} id The ID of the catalogue
     */
    ftpCatalogueDetails: {
      type: Object,
      default: null,
    },

    /**
     * string containing the tab ID that needs to be displayed.
     */
    activeTab: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      display:
        this.initialProduct.getProductStatus &&
        this.initialProduct.getProductStatus.getProductStatus != "D"
          ? this.format
          : "brief",
      markedRecords: this.searchId
        ? new MarkedRecords(this.$baseUrl, this.searchId)
        : null,
      disseminationErrorStatus: null,
      product: this.initialProduct,
      returnTo:
        "/biblio/detailed/" +
        this.productId +
        "/" +
        this.searchId +
        "/" +
        this.page,
      shareMediaModalId: "shareMediaModal",
      dateOptions: {
        day: "dd",
        month: "MMMM",
        year: "yyyy",
        separator: " ",
        format: this.$userPreferences.dateFormat,
      },
      tour: {
        name: "biblio-detailed",
      },
    };
  },

  computed: {
    hasSearchForm: function () {
      return this.searchFormType !== "quick";
    },
    /**
     * Sets the default tab which the page should display
     * on load. Uses the prop to identify which search form
     * the page was loaded from.
     */
    defaultTab: function () {
      // specified active tabe overrules the default tab
      if (this.activeTab !== "") {
        return this.activeTab;
      }

      switch (this.searchForm) {
        case "workflow":
        case "foreignrights":
          return this.searchForm;
        default:
          return "details";
      }
    },
    /**
     * Creates the URL for checking disseminations for
     * errors and warnings
     */
    disseminationCheckUrl: function () {
      return (
        "/scripts/" +
        this.$appId +
        "/extsupply/quickcheck/" +
        this.productId +
        "?L=" +
        this.logonId
      );
    },

    /**
     * Identifies whether custom fields should be displayed
     * on the product page
     */
    hasCustomFields: function () {
      if (this.customFields && this.customFields.length > 0) {
        return true;
      }
      return false;
    },

    /**
     * Identifies whether the product is E-book format
     */
    isEbook: function () {
      return this.product.getFormatName == "E-book";
    },

    /**
     * Defines the steps for the page tour
     */
    tourSteps: function () {
      let steps = [
        {
          target: '[data-v-step="biblio-detail-navigating"]',
          content:
            "You can jump to a different record within the results list by using this box. Note that this number refers to the position in the search results, not the record number. Use the Next and Previous buttons for navigation between records in the search results.",
          offset: -400,
        },
        {
          target: '[data-v-step="biblio-detail-menubar"]',
          content:
            'The links above this section retain all of the functionality from the previous detailed display but you can now access data export and dissemination functionality from all results lists using the "Export" link.<br>If your subscription includes the marketing material module for generating AI sheets, tip sheets and catalogues, you can now also access this from all detailed displays to avoid having to navigate to the marketing material module to generate documents.',
          offset: -400,
        },
        {
          target: "#detailtab___BV_tab_button__",
          content: `Use the record tabs to see different types of information about the record
              <div class="text-left">
              <ul>
              <li><b>Details</b> - metadata, images and other related data.</li>
              <li><b>Disseminations</b> - where and when this record has been sent.</li>
              <li><b>Statistics</b> - quality assurance of the record data including errors and warnings for each data recipient</li>
              <li><b>Auditing information</b> - see an history of changes made to the record</li>
              <li><b>Workflow</b> - access to any workflows associated with the record</li>
              <li><b>Foreign rights</b> - view and edit any rights associated with the record</li>
              </ul>
              </div>`,
          offset: -700,
          params: {
            placement: "right",
          },
        },
        {
          target: '[data-v-step="biblio-detail-cards"]',
          content:
            "Fields in the detailed display are grouped into logical sections to make it easier to find your way around the page. Use the <b>Jump to</b> drop down menu to move quickly between the different sections.",
          offset: -400,
        },
        {
          target: '[data-v-step="biblio-detail-coverimage"]',
          content:
            "Hover over the image to see basic information about the file. Use the icons in the bottom right corner to open the image in a new tab, download the image or send it by email.",
          offset: -400,
        },
      ];
      if (this.$permitted("biblio/edit")) {
        steps.push(
          {
            target: '[data-v-step="biblio-detail-edit"]',
            content:
              "Click on the edit button <i class='fa-solid fa-edit'></i> to open the correct form for editing that section’s data.",
            offset: -400,
          },
          {
            target: '[data-v-step="biblio-detail-filedrop"]',
            content:
              "You can now drag and drop an image file into this section from your computer’s file explorer. When you drop a file, you will be prompted to complete information about the file (cover image, sample content etc)",
            offset: -400,
          }
        );
      }
      if (this.display !== "breif") {
        steps.push({
          target: '[data-v-step="biblio-detail-relatedproducts"]',
          content:
            "This section shows cover images and basic bibliographic information for each product that has been linked to this one such as alternative formats or titles by the same author. Click on a title to view the related product in full.",
          offset: -400,
        });
      }
      return steps;
    },
  },

  mounted: function () {
    // make the share media modal accessible globally
    this.$root.$refs[this.shareMediaModalId] =
      this.$refs[this.shareMediaModalId];

    HTTP.get(this.disseminationCheckUrl).then((response) => {
      this.disseminationErrorStatus = response.data;
    });

    // scroll to page anchor
    if (window.location.hash) {
      var hash = window.location.hash.substring(1);
      const el = document.getElementById(hash);
      if (el != null) {
        setTimeout(function () {
          el.scrollIntoView();
        }, 0);
      }
    }
  },

  methods: {
    /**
     * Called when the status is updated in the menu bar
     */
    onStatusChanged: function (status, products, user, date) {
      if (!this.product.getProductStatus.getProductStatus) {
        return;
      }
      switch (status) {
        case "approve":
          this.product.getProductStatus.getProductStatus = "A";
          // set approved status
          this.product.getProductStatus.getApprovedOn = date;
          this.product.getProductStatus.getApprovedBy = {
            getName: user.name,
            getUserId: user.user_name,
            getUserIdNum: user.user_id,
          };

          // clear archived status
          // this.product.getProductStatus.getArchivedOn = null;
          this.product.getProductStatus.getArchivedOn = null;
          this.product.getProductStatus.getArchivedBy = null;
          break;
        case "unapprove":
        case "restore":
          this.product.getProductStatus.getProductStatus = "P";
          // clear approved status
          this.product.getProductStatus.getApprovedOn = null;
          this.product.getProductStatus.getApprovedBy = null;
          // clear archived status
          this.product.getProductStatus.getArchivedOn = null;
          this.product.getProductStatus.getArchivedBy = null;
          break;
        case "archive":
          this.product.getProductStatus.getProductStatus = "D";
          // clear approved status
          this.product.getProductStatus.getApprovedOn = null;
          this.product.getProductStatus.getApprovedBy = null;
          // set archived status
          this.product.getProductStatus.getArchivedOn = date;
          this.product.getProductStatus.getArchivedBy = {
            getName: user.name,
            getUserId: user.user_name,
            getUserIdNum: user.user_id,
          };
          this.display = "brief";
          break;
        case "delete":
          this.product = {
            getProductStatus: {
              getProductStatus: "deleted",
            },
          };
          break;
      }
      this.product = _cloneDeep(this.product);
    },

    /**
     * Called when tags are saved from the menu bar
     *
     * Passes the event to the productTags child component
     */
    onTagsApplied: function (tagsToRemove, tagsToAdd) {
      this.$refs.biblioDetailTab.$refs.productTags.onTagsApplied(
        tagsToRemove,
        tagsToAdd
      );
    },

    /**
     * Called when a tag is created from the menu bar
     *
     * Passes the event to the productTags child component
     */
    onTagCreated: function (id, name, level) {
      this.$refs.biblioDetailTab.$refs.productTags.onTagCreated(
        id,
        name,
        level
      );
    },

    /**
     * Called when the format in the menu bar is changed
     *
     * Updates the local display setting
     */
    onFormatChanged: function (format) {
      this.display = format;
    },

    /**
     * The sort menu item has been clicked
     */
    sortBtnClick() {
      this.$refs.sortModal.showModal();
    },

    /**
     * A mark menu item has been clicked
     */
    markClick(event, action) {
      switch (action) {
        case "all":
          this.markedRecords.markAll();
          break;
        case "none":
          this.markedRecords.clearAll();
          break;
        case "reverse":
          this.markedRecords.invertAll();
          break;
      }
    },

    /**
     * Updates local object when workflow is deleted
     */
    workflowDeleted: function () {
      this.product.getWorkflow = null;
      this.product.getWorkflowTasks = [];
    },

    /**
     * Updates local object when foreignrights are deleted
     */
    foreignrightsDeleted: function () {
      this.product.getForeignRights.get = [];
    },

    /**
     * Called when the detail page tab is changed
     */
    tabChanged: function (newTabIndex, prevTabIndex, event) {
      // statistics tab
      if (
        event.vueTarget.tabs[newTabIndex].$options.propsData.title ==
        "Statistics"
      ) {
        // load statistics data
        this.$refs.biblioDetailStatistics.loadData();
      }
      // onix tabs
      if (
        event.vueTarget.tabs[newTabIndex].$options.propsData.title ==
        "Onix 2.1 View"
      ) {
        // load statistics data
        this.$refs.onix2.loadData();
      }
      if (
        event.vueTarget.tabs[newTabIndex].$options.propsData.title ==
        "Onix 3.0 View"
      ) {
        // load statistics data
        this.$refs.onix3.loadData();
      }
    },

    preferencesChanged: function (newValues) {
      this.$userPreferences.dateFormat = newValues.dateFormat;
      this.dateOptions.format = newValues.dateFormat;
    },
  },
};
</script>

<style></style>
